import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#571D8D",
      light: "#8050B8",
      veryLight: "#F2F4FB",
      dark: "#391766",
      veryDark: "#1A0133",
    },
    secondary: {
      main: "#ADD656",
      light: "#E7FD96",
      dark: "#7B9738",
    },
    text: {
      main: "#303A3D",
    },
    common: {
      background1: "#F1ECD1",
      background2: "#556A6A",
      reviewStarIcon: "#96A608", //"FFD56A",
      blogGrey: "#F8F8FA",
      blogDarkGrey: "#918684",
      blogGreen: "#EBF7F6",
      commentGreen: "#F8FDF2",
      red: "#CA3F67",
      black: "#020201",
      offBlack: "#2A363B",
      offWhite: "#F5F0EB",
      outOfStock: "#9E9E9E",
      disabledGrey: "#bdbdbd",
      lineGrey: "#d2d3db",
      login: "#f9940b",
      matcha: "#74A12E",
      darkMatcha: "#475629",
      veryDarkMatcha: "#283d28",
      darkGreyGreen: "#394345",
      veryDarkGreyGreen: "#282a2c",
      cartPanel: "fdfcfd", //"#f9f8f9", //"#F5FEFD", //"#f9f9f9",
    },
    error: {
      main: "#FF6161",
    },
    success: {
      main: "#96A608",
    },
  },
  typography: {
    h1: {
      fontFamily: ["GenEiAntique-Eng", "Galdeano"].join(","),
      fontSize: "4rem",
      letterSpacing: "0.375rem",
      fontWeight: 400,
      color: "#391766",
    },
    h2: {
      fontFamily: ["GenEiAntique-Eng", "Galdeano"].join(","),
      fontSize: "3rem",
      letterSpacing: "1px",
      fontWeight: 400,
      color: "#391766",
    },
    h3: {
      fontFamily: "Barlow",
      fontSize: "2.5rem",
      letterSpacing: "1px",
      fontWeight: 400,
      color: "#391766",
    },
    h4: {
      fontFamily: "Barlow",
      fontSize: "2rem",
      letterSpacing: "1px",
      fontWeight: 600,
      color: "#391766",
    },
    h5: {
      fontFamily: ["GenEiAntique-Eng", "Galdeano"].join(","),
      fontSize: "2rem",
      letterSpacing: "1px",
      fontWeight: 300,
      color: "#391766",
    },
    h6: {
      fontFamily: "Barlow",
      fontSize: "1.5rem",
      letterSpacing: "1px",
      fontWeight: 300,
      color: "#391766",
    },

    body1: {
      fontFamily: "Barlow",
      fontSize: "1rem",
      letterSpacing: "0px",
      fontWeight: 300,
      color: "#391766",
    },

    body2: {
      fontFamily: "Barlow",
      fontSize: "1rem",
      letterSpacing: "0px",
      fontWeight: 400,
      color: "#391766",
    },

    body3: {
      fontFamily: "Barlow",
      fontSize: "1rem",
      letterSpacing: "0px",
      fontWeight: 600,
      color: "#391766",
    },
  },
  overrides: {},
});

export default theme;
