import React from "react"

function Logo({ color, size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      width={size || undefined}
      height={size || undefined} 
      viewBox="0 0 600 600"
    >
      <g id="black">
        <path
          fill={color || "#5f1193"}
          d="M557.66 272.17v114.66c0 46.63-29.88 91.2-79.95 119.23-26.25 14.7-58.78 22.79-91.61 25.95H238.84c-32.83-3.16-65.37-11.25-91.62-25.95-50.06-28-79.95-72.6-79.95-119.23V272.17c0-9.05-8.43-16.39-18.82-16.39v131c0 52.23 33.12 102 88.59 133a191.56 191.56 0 0 0 34.84 15c2.22 18.29 18.21 32.86 37.63 38.4h205.91c19.43-5.54 35.41-20.11 37.64-38.41a190.74 190.74 0 0 0 34.83-15c55.47-31.06 88.59-80.79 88.59-133v-131c-10.39.01-18.82 7.35-18.82 16.4ZM178 54.67c-.42 11.57-13.06 18.79-24.19 14.07-1-.4-1.89-.77-2.84-1.13a80.5 80.5 0 0 0-28.5-5q-31.17 0-49.68 17.72T54.3 127.92q0 29.24 17.19 46.38t46.88 17.15a89.66 89.66 0 0 0 31.43-5.38c1-.36 1.93-.73 2.88-1.13 11.28-4.66 23.91 2.94 23.83 14.63v1.32a16 16 0 0 1-9.63 14.47 112.66 112.66 0 0 1-18.34 6.34 125.28 125.28 0 0 1-30.7 3.67q-46.62 0-76.52-27.53t-29.91-69.92q0-42.27 30-69.74t77.25-27.46a146 146 0 0 1 30.64 3.16 137.51 137.51 0 0 1 17.63 5A16.28 16.28 0 0 1 178 54.67"
        />
        <path
          fill={color || "#5f1193"}
          d="M221.06 255.78c20.34 0 36.83 13.9 36.83 33.31h-52.95c-6 0-10.78 4.49-10.78 10v148.25c-24 0-36.84-18.57-36.84-41.48V299.13c0-5.55-4.83-10-10.78-10h-10.6c-20.35 0-37.61-13.91-37.61-33.31ZM341.87 65.08a20 20 0 0 1 19.84-19.83 19.52 19.52 0 0 1 14.16 5.8 18.92 18.92 0 0 1 5.91 14 19.12 19.12 0 0 1-5.91 14.1A19.46 19.46 0 0 1 361.71 85a18.86 18.86 0 0 1-14-6 19.35 19.35 0 0 1-5.86-14m3 165.35v-83.18a33.93 33.93 0 0 1 33.93-33.94v83.27a33.93 33.93 0 0 1-33.93 33.93M545.56 65.08a20 20 0 0 1 19.83-19.83 19.49 19.49 0 0 1 14.16 5.8 18.92 18.92 0 0 1 5.91 14 19.12 19.12 0 0 1-5.91 14.1A19.43 19.43 0 0 1 565.39 85a18.88 18.88 0 0 1-14-6 19.34 19.34 0 0 1-5.85-14m3 165.35v-83.18a33.93 33.93 0 0 1 33.93-33.94v83.27a33.93 33.93 0 0 1-33.93 33.93M481.24 216l-33.64-49.23 37.4-50.35a8.11 8.11 0 0 1 6.53-3.19h17.05c6.59 0 10.31 7 6.28 11.87l-29.8 40 41.79 65.35h-18.31a33.09 33.09 0 0 1-27.3-14.45ZM447.6 230.44a36.69 36.69 0 0 1-36.69-36.69V45.25a36.69 36.69 0 0 1 36.69 36.69ZM199.42 195.25V41.54c20.05 0 36.31 15.76 36.31 35.19v28.79a55.06 55.06 0 0 1 13.68-6.25 59.27 59.27 0 0 1 14.67-1.51q32.09 0 41.54 25 3.74 10 3.73 33.54v74.1c-20.06 0-36.31-15.76-36.31-35.19v-36.37q0-15.76-4.36-22.9t-14.05-7.14q-9.94 0-14.42 7.51t-4.48 24.29v69.84c-20 0-36.31-15.76-36.31-35.19M467.1 306.44h5.36c12 0 25.27 10.37 25.27 23.16v106.72h-15c-8.61 0-15.59-7.43-15.59-16.6q-16.67 16.61-35.86 16.6-24.23 0-40.06-18.64-15.71-19-15.71-47.51 0-28 15.71-46.62t39.34-18.65c13.6 0 25.79 2.17 36.58 14.09Zm-62.75 63.76q0 16.91 8.72 27.54c6 7.17 12.53 10.75 21.59 10.75q14.54 0 23.48-10.38c6-7.17 5-17.13 5-28.16s.94-19.28-5-26.45Q449.2 333 434.9 333c-9 0-15.55 3.54-21.6 10.63s-8.95 16-8.95 26.58"
        />
        <path
          fill={color || "#5f1193"}
          d="M343.25 377H272c-5.95 0-10.23 5.85-8.7 11.79 7.36 28.61 45.69 29.13 63.83 12.67 7.38-6.69 17.66-8.69 26.24-3.75l6.82 3.93c-19 42.2-80.08 51.27-110 19.52-23.58-23.52-23.9-76.11.59-100 38.91-37.67 100.92-19.7 110 31.8 2 11.34-6.38 24-17.55 24m-17.43-38.52c-14.25-21.1-54.9-17.11-58.77 13.12h51.66c7.08 0 11.24-7 7.11-13.12"
        />
      </g>
    </svg>
  )
  
}

export default Logo