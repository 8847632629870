import React from "react";

import { ThemeProvider } from "@mui/material/styles";
import { ApolloWrapper } from "../../apollo/ApolloWrapper";
import { ParallaxProvider } from "react-scroll-parallax";
import {
  PaymentCurrenciesWrapper,
  CurrencyWrapper,
  IntroducerWrapper,
  UserWrapper,
  FeedbackWrapper,
  CartWrapper,
} from "../../contexts";

import theme from "./theme";

const element = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <ApolloWrapper>
          <PaymentCurrenciesWrapper>
            <CurrencyWrapper>
              <IntroducerWrapper>
                <UserWrapper>
                  <FeedbackWrapper>
                    <CartWrapper>{element}</CartWrapper>
                  </FeedbackWrapper>
                </UserWrapper>
              </IntroducerWrapper>
            </CurrencyWrapper>
          </PaymentCurrenciesWrapper>
        </ApolloWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

export default element;
