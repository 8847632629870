export const SET_INTRODUCER = "SET_INTRODUCER";

export const SET_USER = "SET_USER";

export const SET_CURRENCY = "SET_CURRENCY";
export const SET_PAYMENT_CURRENCIES = "SET_PAYMENT_CURRENCIES";

export const SET_SNACKBAR = "SET_SNACKBAR";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";

export const CHANGE_FREQUENCY = "CHANGE_FREQUENCY";
export const TOGGLE_SUBSCRIPTION = "TOGGLE_SUBSCRIPTION";
