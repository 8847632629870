import React, { useState, useEffect } from "react";
import theme from "./theme";

function SpinningLogo({ Logo, size, color }) {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRotation((prevRotation) => prevRotation + 1);
    }, 5);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{ transform: `rotateY(${rotation}deg)` }}>
      <Logo color={color || theme.palette.primary.main} size={size || "2rem"} />
    </div>
  );
}

export default SpinningLogo;
