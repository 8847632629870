import React, { useEffect, useReducer, createContext } from "react";

import { useQuery } from "@apollo/client";
import { setPaymentCurrencies } from "../actions";
import { GET_PAYMENT_CURRENCIES } from "../../apollo/queries";

import paymentCurrenciesReducer from "../reducers/payment-currencies-reducer";

export const PaymentCurrenciesContext = createContext();
const PaymentCurrenciesProvider = PaymentCurrenciesContext.Provider;

export function PaymentCurrenciesWrapper({ children }) {
  const defaultPaymentCurrencies = [
    {
      attributes: {
        name: "UK Pounds",
        description: "UK British Pounds Sterling",
        codeISO: "GBP",
        symbol: "£",
        rateToBase: 1,
        decimals: 2,
        allowPayments: true,
      },
    },
  ];

  const storedPaymentCurrencies =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("paymentCurrencies"))
      : null;

  const [paymentCurrencies, dispatchPaymentCurrencies] = useReducer(
    paymentCurrenciesReducer,
    storedPaymentCurrencies || defaultPaymentCurrencies
  );

  // query (via Apollo) for payment currency info
  const { loading, error, data } = useQuery(GET_PAYMENT_CURRENCIES);

  useEffect(() => {
    if (loading) {
      console.log("paymentCurrencies loading...");
    } else if (error) {
      console.error(error);
      dispatchPaymentCurrencies(setPaymentCurrencies(defaultPaymentCurrencies));
    } else if (data) {
      let queryResult = data.currencies.data;
      dispatchPaymentCurrencies(setPaymentCurrencies(queryResult));
      console.log("paymentCurrencies set.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, data]);

  return (
    <PaymentCurrenciesProvider
      value={{ paymentCurrencies, dispatchPaymentCurrencies }}
    >
      {children}
    </PaymentCurrenciesProvider>
  );
}
