export default function getCountryCurrencyCode(country) {
  let ccyCode;

  switch (country) {
    case "United Kingdom":
      ccyCode = "GBP";
      break;
    case "Austria":
    case "Belgium":
    case "Cyprus":
    case "Estonia":
    case "Finland":
    case "France":
    case "Germany":
    case "Greece":
    case "Ireland":
    case "Italy":
    case "Latvia":
    case "Lithuania":
    case "Luxembourg":
    case "Malta":
    case "Netherlands":
    case "Portugal":
    case "Slovakia":
    case "Slovenia":
    case "Spain":
    case "Bulgaria":
    case "Croatia":
    case "Czechia":
    case "Hungary":
    case "Poland":
    case "Romania":
    case "Sweden":
    case "Norway":
    case "Ukraine":
    case "Turkey":
    case "Denmark":
    case "Iceland":
    case "Republic of Cyprus":
      ccyCode = "EUR";
      break;
    case "Japan":
      ccyCode = "JPY";
      break;
    default:
      ccyCode = "USD";
      break;
  }

  return ccyCode;
}
