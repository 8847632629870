exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-favorites-js": () => import("./../../../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduce-js": () => import("./../../../src/pages/introduce.js" /* webpackChunkName: "component---src-pages-introduce-js" */),
  "component---src-pages-manage-js": () => import("./../../../src/pages/manage.js" /* webpackChunkName: "component---src-pages-manage-js" */),
  "component---src-pages-order-confirmation-js": () => import("./../../../src/pages/order-confirmation.js" /* webpackChunkName: "component---src-pages-order-confirmation-js" */),
  "component---src-pages-print-order-card-js": () => import("./../../../src/pages/print-order-card.js" /* webpackChunkName: "component---src-pages-print-order-card-js" */),
  "component---src-pages-rewards-js": () => import("./../../../src/pages/rewards.js" /* webpackChunkName: "component---src-pages-rewards-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/ArticlePost.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/ContentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-cultivar-page-js": () => import("./../../../src/templates/CultivarPage.js" /* webpackChunkName: "component---src-templates-cultivar-page-js" */),
  "component---src-templates-product-detail-js": () => import("./../../../src/templates/ProductDetail.js" /* webpackChunkName: "component---src-templates-product-detail-js" */),
  "component---src-templates-product-list-js": () => import("./../../../src/templates/ProductList.js" /* webpackChunkName: "component---src-templates-product-list-js" */)
}

