import { SET_PAYMENT_CURRENCIES } from "../actions/action-types";

export default function paymentCurrenciesReducer(state, action) {
  const { paymentCurrencies } = action.payload;

  // to avoid mutating the original state
  let newState = { ...state };

  switch (action.type) {
    case SET_PAYMENT_CURRENCIES:
      localStorage.setItem(
        "paymentCurrencies",
        JSON.stringify(paymentCurrencies)
      );

      newState = paymentCurrencies;

      return newState;
    default:
      return state;
  }
}
