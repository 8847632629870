import { SET_USER } from "../actions/action-types";

export default function userReducer(state, action) {
  // to avoid mutating the original state
  let newState = { ...state };
  const { user } = state.user || action.payload;
  //console.log("state.user:", state.user);
  //console.log("action.payload", action.payload);
  if (user.introducedByCode === "null") {
    user.introducedByCode = null;
  }
  //console.log("repaired user:", user);
  const userToSet = user;
  //console.log("user to be set:", userToSet);
  switch (action.type) {
    case SET_USER:
      if (userToSet.username === "Guest") {
        localStorage.removeItem("user");
      } else {
        console.log("setting user:", userToSet);
        localStorage.setItem("user", JSON.stringify(userToSet));
      }

      newState = userToSet;

      return newState;
    default:
      return state;
  }
}
