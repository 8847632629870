import { SET_INTRODUCER } from "../actions/action-types";

export default function introducerReducer(state, action) {
  const { introducer } = action.payload;

  // to avoid mutating the original state
  let newState = { ...state };

  switch (action.type) {
    case SET_INTRODUCER:
      localStorage.setItem("introducer", JSON.stringify(introducer));

      newState = introducer;

      return newState;
    default:
      return state;
  }
}
