import React, { useEffect, useReducer, createContext } from "react";

import currencyReducer from "../reducers/currency-reducer";
import { setCurrency } from "../actions";

export const CurrencyContext = createContext();
const CurrencyProvider = CurrencyContext.Provider;

export function CurrencyWrapper({ children }) {
  const defaultCurrency = { country: "United Kingdom", codeISO: "GBP" };

  const storedCurrency =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("currency"))
      : null;
  const [currency, dispatchCurrency] = useReducer(
    currencyReducer,
    storedCurrency || defaultCurrency
  );

  // updates currency on page refresh
  useEffect(() => {
    if (storedCurrency) {
      // DISPATCH STORED CURRENCY
      console.log("dispatching stored currency...", storedCurrency);
      dispatchCurrency(setCurrency(storedCurrency));
    } else {
      console.log("dispatching default currency...", defaultCurrency);
      dispatchCurrency(setCurrency(defaultCurrency));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrencyProvider value={{ currency, dispatchCurrency, defaultCurrency }}>
      {children}
    </CurrencyProvider>
  );
}
