import RootWrapper from "./src/components/ui/root-wrapper";
import "./global.css";
import "@fontsource/klee-one/400.css";
import "@fontsource/klee-one/600.css";
import "@fontsource/galdeano/400.css";
import "@fontsource/gilda-display/400.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/600-italic.css";
import "@fontsource/rokkitt/400.css";
import "@fontsource/heebo/400.css";
import "@fontsource/heebo/300.css";
import "@fontsource/barlow/200.css";
import "@fontsource/barlow/300.css";
import "@fontsource/barlow/400.css";
import "@fontsource/barlow/600.css";
import "@fontsource/roboto/400.css";

export const wrapRootElement = RootWrapper;
