import { SET_CURRENCY } from "../actions/action-types";

export default function currencyReducer(state, action) {
  const { currency } = action.payload;

  // to avoid mutating the original state
  let newState = { ...state };

  switch (action.type) {
    case SET_CURRENCY:
      localStorage.setItem("currency", JSON.stringify(currency));

      newState = currency;

      return newState;
    default:
      return state;
  }
}
