import React, { useReducer, createContext, useState, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";
import { setSnackbar } from "../actions";
import feedbackReducer from "../reducers/feedback-reducer";

export const FeedbackContext = createContext();

const FeedbackProvider = FeedbackContext.Provider;

export function FeedbackWrapper({ children }) {
  const [feedbackQueue, setFeedbackQueue] = useState([]);
  const [currentSnackbar, setCurrentSnackbar] = useState(null);

  useEffect(() => {
    if (feedbackQueue.length > 0 && !currentSnackbar) {
      setCurrentSnackbar(feedbackQueue[0]);
      setFeedbackQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [feedbackQueue, currentSnackbar]);

  const handleCloseSnackbar = () => {
    setCurrentSnackbar(null);
  };

  const dispatchFeedback = (snackbar) => {
    setFeedbackQueue((prevQueue) => [...prevQueue, snackbar]);
  };

  return (
    <FeedbackProvider value={{ dispatchFeedback }}>
      {children}
      <Snackbar
        open={Boolean(currentSnackbar)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={8000}
        onClose={handleCloseSnackbar}
      >
        {currentSnackbar && (
          <Alert
            variant="filled"
            sx={{ minWidth: "400px" }}
            severity={currentSnackbar.payload.status || "info"}
            onClose={handleCloseSnackbar}
          >
            {currentSnackbar.payload.message}
          </Alert>
        )}
      </Snackbar>
    </FeedbackProvider>
  );
}

{
  /*import React, { useReducer, createContext } from "react";

import { Snackbar, Alert } from "@mui/material";
import { setSnackbar } from "../actions";
import feedbackReducer from "../reducers/feedback-reducer";

export const FeedbackContext = createContext();

const FeedbackProvider = FeedbackContext.Provider;

export function FeedbackWrapper({ children }) {
  const [feedback, dispatchFeedback] = useReducer(feedbackReducer, {
    open: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackbar = () => {
    dispatchFeedback(setSnackbar({ open: false }));
  };

  return (
    <FeedbackProvider value={{ feedback, dispatchFeedback }}>
      {children}
      <Snackbar
        open={feedback.open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={8000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          variant="filled"
          sx={{ minWidth: "400px" }}
          severity={feedback.severity}
          onClose={handleCloseSnackbar}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </FeedbackProvider>
  );
}
*/
}
